// https://github.com/authts/oidc-client-ts
import type { UserManagerSettings } from 'oidc-client-ts';
import { Log as OidcClientLog, User, UserManager } from 'oidc-client-ts';

import history from '@rikstv/play-common/src/router/history';

import AppConfig, { AuthConfig } from '../../config';
import { AccessToken } from '../../forces/auth/auth.types';
import { errorTracker } from '../errorTracker/tracking';
import { isRunningTests } from '../isRunningTests';
import { getLogger } from '../logger/logger';
import { Deferred } from '../promises/Deferred';

import { isMockUser, MockAuthService } from './__mocks__/MockAuthService';
import { JwtToken } from './jwtToken';
import { getUserStorage } from './userStorage';

const oidcLogger = getLogger('[auth.oidc]');

// oidc-client log level
OidcClientLog.setLevel(location.origin.includes('localhost') ? OidcClientLog.INFO : OidcClientLog.ERROR);
OidcClientLog.setLogger(oidcLogger);

const log = getLogger('[auth]');
if (isRunningTests()) {
  log.disableAll();
  OidcClientLog.setLevel(OidcClientLog.NONE);
}

const _loginAttemptTimeKey: Readonly<string> = 'login_attempt';
const _silentAuthSupportKey: Readonly<string> = 'silent_auth_support';

let _silentRenewTimestamp = new Date(0);

type InitStates = 'signedIn' | 'signedOut' | 'signInFailed' | 'noAction';
type LoginOverrides = Partial<
  Pick<UserManagerSettings, 'redirect_uri' | 'acr_values' | 'extraQueryParams' | 'silentRequestTimeoutInSeconds'>
> &
  Partial<{ state: string }>;

interface RenewOptions {
  notifyOnRenew?: boolean;
  redirectUrl?: string;
}
type TokenChangedHandler = (token: string | null) => void;

const StateConstants = {
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
} as const;

class AuthService {
  private userManager: Readonly<UserManager>;
  private token: JwtToken = new JwtToken(null);
  private callbackUrl: Readonly<URL>;
  private logoutCallbackUrl: Readonly<URL>;
  private onTokenRenewalCompleted?: () => void;
  private supportsSilentAuth: boolean = true;
  private onChangeSubscribers: Array<TokenChangedHandler> = [];
  private initializedPromise = new Deferred<InitStates>();
  private lazyInit?: boolean;

  whenReady = this.initializedPromise.then; // "then" is already a bound fn in Deferred

  constructor({ lazyInit, ...authConfigOverrides }: Partial<typeof AuthConfig> & { lazyInit?: true } = {}) {
    this.lazyInit = lazyInit;
    const authConfig = { ...AuthConfig, ...authConfigOverrides };
    // with local overrides
    const notificationTime =
      parseFloat(sessionStorage.getItem('config.auth.notificationTime') || '') || authConfig.tokenExpirationBuffer;

    this.callbackUrl = new URL(authConfig.oidcCallbackUrl);
    this.logoutCallbackUrl = new URL(authConfig.oidcPostLogoutUrl);

    if (sessionStorage.getItem(_silentAuthSupportKey) === 'false') {
      this.supportsSilentAuth = false;
    }

    const usrMgrSettings: UserManagerSettings = {
      authority: authConfig.stsUrl,
      scope: authConfig.scope,
      client_id: authConfig.clientId,
      redirect_uri: this.callbackUrl.href,
      response_type: 'code',
      loadUserInfo: false,
      includeIdTokenInSilentRenew: true,
      validateSubOnSilentRenew: true,
      revokeTokensOnSignout: true,
      /** The number of seconds before an access token is to expire to raise the accessTokenExpiring event (default: 60) */
      accessTokenExpiringNotificationTimeInSeconds: notificationTime,
      userStore: getUserStorage(),
      // Set to true (or remove) to monitor OP login state
      monitorSession: false,
      // We handle this ourselves
      automaticSilentRenew: false,
      silent_redirect_uri: authConfig.oidcSilentRenewUrl,
      silentRequestTimeoutInSeconds: 2,
    };
    this.userManager = new UserManager(usrMgrSettings);
    log.info('AuthService', {
      usrMgrSettings,
      supportsSilent: this.supportsSilentAuth,
    });

    this.userManager.events.addAccessTokenExpiring(async (...args) => {
      log.log('onAccessTokenExpiring', args);

      // If the difference between AccessTokenLifetime (STS) and
      // accessTokenExpiringNotificationTime (config for this app) is less than 15 seconds (value below)
      // the user will be logged out when access token expires (no renew). User must then click "login" or refresh
      // browser. Hence; the AccessTokenLifetime on STS and accessTokenExpiringNotificationTime must be set in
      // coordination so it makes sense.
      if (this.token.isTokenExpired() || Date.now() - _silentRenewTimestamp.getTime() > 15_000) {
        _silentRenewTimestamp = new Date();
        await this.trySilentSignIn('expires-soon', true, { silentRequestTimeoutInSeconds: 10 });
      }
    });

    this.userManager.events.addAccessTokenExpired(() => {
      log.log('onAccessTokenExpired');
    });

    // Default trigger init here, but for testing lazyInit makes it easier to mock things
    // before making calls to managers and whatnot
    if (this.lazyInit !== true) {
      this._init().then(s => this.initializedPromise.resolve(s));
    }
  }

  private async _init() {
    log.info('init: Initializing AuthService');

    if (this.isCallbackPath(window.location.pathname)) {
      return await this.handleLoginCallback();
    }
    if (this.isSignoutCallback()) {
      const res = await this.userManager.signoutRedirectCallback();
      log.info('Signout response', res);
      if (res.state === StateConstants.LOGOUT_SUCCESS) {
        await this.cleanup();
        return 'signedOut';
      }
    }

    // See if we have a user in user storage
    let user = await this.userManager.getUser();
    log.log(`init: user from storage (expired: ${user?.expired || false})`, user);

    this.setToken(user?.access_token ?? null);
    if (this.token.isTokenValidFor(0)) {
      return 'signedIn';
    } else {
      // remove user from storage if token is expired
      // otherwise we'll still serve expired token via "useAuthToken" hook
      await this.userManager.removeUser();
      user = null;
      this.setToken(null);
    }

    // see if user is signed in to Identity Provider
    // only works in browsers that support 3rd party cookies, i.e. only Chrome for now and they are removing support in 2024...
    const autoLoginShouldWork = await this.isLoggedInToIdentityProvider();
    if (!autoLoginShouldWork) {
      return 'noAction';
    }

    // Case 1. Has outdated token AND is logged in on STS -> easy peasy
    // Case 2. Has outdated token AND is NOT logged in on STS -> should not auto-fix
    const state = await this.trySilentSignIn('init', false);
    if (state !== 'success') {
      log.info('silent signin not supported');
      // TODO: uncomment next 2 lines "some time" after deploy
      // this.supportsSilentAuth = false;
      // sessionStorage.setItem(_silentAuthSupportKey, 'false');

      // browser does not support silent signin (3rd party cookies)
      // so we'll do a good old redirect here
      await this.login();
    }
    return this.token.isTokenValidFor(0) ? 'signedIn' : 'noAction';
  }

  async init(tokenRenewedCallback: () => void): Promise<InitStates> {
    this.onTokenRenewalCompleted = tokenRenewedCallback;
    // real work (usually) triggered last in constructor
    if (this.lazyInit === true) {
      this.lazyInit = false;
      this._init().then(s => this.initializedPromise.resolve(s));
    }
    return this.initializedPromise.promise;
  }

  isAuthenticated(): boolean {
    return this.token.isTokenValidFor(0);
  }

  isInternalUser(validEmailPrefixes: RegExp[] = []): boolean {
    const inDomain = this.token.isUserInDomain();
    if (inDomain && validEmailPrefixes.length) {
      const email = this.token.valueOf()?.email ?? '';
      return validEmailPrefixes.some(rg => rg.test(email));
    }
    return inDomain;
  }

  async logout() {
    const user = await this.userManager.getUser();

    const args = {
      id_token_hint: user?.id_token,
      post_logout_redirect_uri: this.logoutCallbackUrl.href,
      state: StateConstants.LOGOUT_SUCCESS,
    };

    await this.userManager.signoutRedirect(args);
  }

  async login(args: LoginOverrides = {}) {
    log.info('Login', args);

    // user already authenticated, redirect right away
    if (this.token.isTokenValidFor(0)) {
      return window.location.replace(this.getLoginRedirect());
    }
    localStorage.setItem(_loginAttemptTimeKey, Date.now().toString());
    args.state ??= this.getLoginRedirect();

    await this.userManager.signinRedirect(args);
  }

  async renewTokens({ redirectUrl = '', notifyOnRenew = true }: RenewOptions = {}) {
    const state = await this.trySilentSignIn('renew', notifyOnRenew);
    if (state === 'success') {
      if (redirectUrl && redirectUrl !== window.location.href) {
        history.push(redirectUrl);
      }
      return;
    }
    // fallback to regular ping-pong redirect
    await this.userManager.signinRedirect({ state: redirectUrl });
  }

  async redirectToAuthServerAfterPasswordReset({ email }: { email: string }) {
    const args: LoginOverrides = {
      extraQueryParams: {
        loginContext: 'password_changed',
        email,
      },
    };
    await this.userManager.signinRedirect(args);
  }

  async loginSilentWithOneTimeCode({ email, otac }: { email: string; otac: string }): Promise<User | null | void> {
    const args: LoginOverrides = {
      acr_values: `otac:${otac}`,
      extraQueryParams: {
        loginContext: 'user_created',
        email,
      },
    };
    log.info('login with otac', args);
    // use 15s (not 2s) here since much more important this succeeds than regular token-refresh
    // we want this issue to disappear: https://rikstv-h0.sentry.io/issues/4480679195/?project=5783662
    const state = await this.trySilentSignIn('user_created', false, { ...args, silentRequestTimeoutInSeconds: 15 });
    if (state === 'success') {
      return await this.userManager.getUser();
    }
    // TODO: handle issues when in simplified signup-flow somehow since redirect-login breaks the entire flow 🤔
    // Fallback to redirect
    args.state = window.location.pathname;
    await this.userManager.signinRedirect(args);
  }

  getToken(): AccessToken {
    return {
      value: this.token.accessToken,
      expiry: this.token.expires,
    };
  }

  getUserData() {
    const decodedToken = this.token.valueOf();
    if (!decodedToken) {
      return null;
    }
    const { sub, email, 'urn:rikstv:1:cid': cid } = decodedToken;
    // 'urn:rikstv:2:channel' is either a string or a string[] (or null) why .concat() works well here
    const entitlements = ([] as string[]).concat(decodedToken['urn:rikstv:2:channel'] ?? []);
    return sub && email ? { userId: sub, email, cid, entitlements } : null;
  }

  async shouldRenewTokensOnRouteChange(): Promise<boolean> {
    if (this.supportsSilentAuth) {
      return false;
    }
    if (this.token.isTokenValidFor(0)) {
      // set offset to 30 sec in dev and 12 hours in prod
      const msIntoFuture = AppConfig.isDevelopment ? 30 * 1000 : 12 * 3600 * 1000;
      if (!this.token.isTokenValidFor(msIntoFuture)) {
        return true;
      }
    } else if (this.token.accessToken) {
      // user logged in to STS so renew should be automatic
      if (await this.isLoggedInToIdentityProvider()) {
        return true;
      } else {
        await this.cleanup();
      }
    }
    return false;
  }
  /**
   * @description register callback that is called with updated token when the value changes due to silent update / logout
   */
  onTokenChanged = (callback: TokenChangedHandler) => {
    this.onChangeSubscribers.push(callback);
    return () => {
      // return cleanup / unsubscribe
      this.onChangeSubscribers = this.onChangeSubscribers.filter(cb => cb !== callback);
    };
  };

  private async cleanup() {
    await this.userManager.removeUser();
    await this.userManager.clearStaleState();
    this.setToken(null);
  }

  private async isLoggedInToIdentityProvider(): Promise<boolean> {
    // if ok response the user is logged in to STS and so silent renew/auth should work
    // if this returns true but silent login fails, that is an indication that this browser
    // does not support 3rd party cookies and authentication must use redirects
    try {
      // NB! does not work for localhost -> sts.uat.strim.no requests in incognito mode
      // But works for uat.strim.no -> sts.uat.strim.no on safari and chrome-incognito
      const ctxEndpoint = `${this.userManager.settings.authority!}/context/check`.replace('//context', '/context'); // remove potential double slash
      const res = await fetch(ctxEndpoint, { credentials: 'include' });
      return res.ok;
    } catch {
      return false;
    }
  }

  private setToken(access_token: string | null, fromCallback = false) {
    this.token = new JwtToken(access_token, fromCallback);
    for (const callback of this.onChangeSubscribers) {
      try {
        callback(access_token);
      } catch {
        /* */
      }
    }
  }

  private async handleLoginCallback(): Promise<InitStates> {
    log.info('AuthService init: Handling authorization callback');

    try {
      const { state = '/', access_token } = await this.userManager.signinRedirectCallback();
      this.setToken(access_token, true);

      if (this.token.hasValidCustomerClaim()) {
        log.info(`redirect from "${window.location.pathname}" to "${state}"`);
        history.push(state as string, { replace: true });
        return 'signedIn';
      } else {
        const decodedToken = this.token.valueOf();
        log.warn('Customer claim is invalid, signing user out...', { decodedToken });
        errorTracker.logMessage('Customer claim is invalid, signing user out...', {
          level: 'warning',
          tags: { auth: 1 },
          extra: { decodedToken },
          fingerprint: ['invalid-customer-claim'],
        });
        // safety catch to avoid infinite redirects to auth server
        // it was possible to get "valid" token with incorrect customer number claim
        await this.logout();
        return 'signInFailed';
      }
    } catch (err) {
      log.error(err);
      errorTracker.captureException(err, { fingerprint: ['sign-in-failed'], tags: { auth: 1 } });
      return 'signInFailed';
    }
  }

  private async trySilentSignIn(
    reason: 'init' | 'renew' | 'user_created' | 'expires-soon',
    notifyOnRenew = true,
    args: LoginOverrides = {}
  ): Promise<'not_supported' | 'success' | 'error'> {
    if (typeof this.userManager.settings.silent_redirect_uri !== 'string') {
      return 'not_supported';
    }
    // supportsSilentAuth = not in Safari or incognito chrome
    // always attempt on sign-up since OTAC-flow is different
    const isOTAC = (args.acr_values || '').startsWith('otac');
    if (!this.supportsSilentAuth && isOTAC === false) {
      return 'not_supported';
    }
    try {
      log.log(`Attempting silent signin (${reason})`, { notifyOnRenew, args });
      const user = await this.userManager.signinSilent(args);
      log.info('Silently signed in', { user });
      this.setToken(user?.access_token ?? null);
      if (notifyOnRenew) {
        this.onTokenRenewalCompleted?.();
      }
      return 'success';
    } catch (err: unknown) {
      const errorMessage = err instanceof Error ? err.message : String(err);
      // TODO: we should not track errors for "login_required" or "invalid_grant" since those are expected to happen
      // TODO: We can instance-check err for "ErrorResponse" and "ErrorTimeout" from oidc-client-ts
      // TODO: maybe handle [init, renew, expires-soon] differently as well...

      errorTracker.captureException(err instanceof Error ? err : new Error(err?.toString()), {
        fingerprint: ['silent-signin-failed'],
        extra: { silentSignInArgs: args },
        tags: { silentSignInReason: reason, errorMessage },
      });

      // Handle logged out of STS
      if (errorMessage === 'login_required') {
        // Remove stored tokens
        await this.cleanup();
      } else if (errorMessage === 'Network Error') {
        // Retry?
      }

      log.error('Failed to silently sign in', err);
    }
    return 'error';
  }

  private getLoginRedirect() {
    const redirect = window.location.pathname + window.location.search;
    return this.isCallbackPath(window.location.pathname) ? '/' : redirect;
  }

  private isCallbackPath(path: string) {
    const pathname = path.toLowerCase();
    const loginCallback = this.callbackUrl.pathname.toLowerCase();
    return pathname === loginCallback;
  }

  private isSignoutCallback() {
    const onSignoutCallbackPath = window.location.pathname === this.logoutCallbackUrl.pathname;
    return onSignoutCallbackPath && window.location.search.startsWith('?state=');
  }
}

// To allow testing framework to mock a valid session we can return a mock auth service
// instead of the proper one. This will make the user appear to be logged in
// but the actual token is not valid, so if you call any service that need a
// token, that will fail
const authService = isMockUser ? new MockAuthService() : new AuthService();
const getJwt = () => authService.getToken().value;

export { authService, AuthService as AuthServiceClass, getJwt };
