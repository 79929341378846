import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { CaptureContext, ScopeContext } from '@sentry/types';

import config from '../../config';
import { RiksTVRequestError } from '../../forces/utils/errors';
import { getDeviceId } from '../device/device.utils';
import { isRunningTests } from '../isRunningTests';
import log from '../logger/logger';

import { EnhancedError } from './errorEnhancer';
import { isError } from './errorTracker.utils';
import { ErrorTrackerFeature } from './errorTrackerFeature';

export class SentryErrorTracker {
  private errorTrackerFeature: ErrorTrackerFeature;

  constructor(errorTrackerFeature: ErrorTrackerFeature) {
    this.errorTrackerFeature = errorTrackerFeature;
    if (this.errorTrackerFeature.thisEnvironmentShouldLogToServer() && config.sentry.DSN) {
      Sentry.init({
        dsn: config.sentry.DSN,
        integrations: [
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
        ],
        initialScope: {
          user: { id: getDeviceId() },
          tags: {
            // Assign version as a tag to make it easier to track bug-fixes across RiksTV/Strim
            // that otherwise end up in different Sentry issues (because they are different projects)
            //   -> "release.version" can't be used in dashboards but only in discover for some reason
            appVersion: config.appVersion,
            // Version string is "YYYY.mm.DD.<x>", where x is number of commits to master that day
            releaseDate: config.appVersion.split('.').slice(0, -1).join('.'),
          },
        },
        release: `${config.sentry.projectName}@${config.appVersion}`,
        debug: config.environment !== 'PROD' && config.environment !== 'PT',
        environment: config.sentry.envName,
        sampleRate: this.errorTrackerFeature.enableForPercentage / 100,
        // Prevent logging errors from scripts that are not ours (pslugin, vergic, etc.)
        allowUrls: [/www\.((dev|uat|pt)\.)?strim\.no/, /play\.((dev|uat|pt)\.)?rikstv\.no/],
        ignoreErrors: [
          'Non-Error exception captured',
          'Non-Error promise rejection captured',
          'TypeError: Failed to fetch',
          'TypeError: NetworkError when attempting to fetch resource.',
          'AbortError: The operation was aborted',
          'TypeError: avbrutt',
          'TypeError: avbruten',
          'A network error occurred.',
        ],
        beforeSend(event) {
          // Modify, fingerprint or drop the event here.
          // Fingerprinting helps Sentry to group issues
          //  "event.fingerprint = ['some-fingerprint'];"
          // Filter out event by returning null
          //  "return null;"
          return event;
        },
        /**
         * Breadcrumbs are the "events leading up to" the exception/error
         * Important to avoid noise here since it's harder to find the reasons for the exception then
         */
        beforeBreadcrumb: breadcrumb => {
          const category = breadcrumb.category as undefined | 'console' | 'fetch' | 'xhr' | 'navigation' | 'ui.click';

          if (category === 'ui.click') {
            if (breadcrumb.message?.includes('#')) {
              // ui element has an id, try to trim away class defs
              breadcrumb.message = breadcrumb.message.split(/\./)[0];
            }
            return breadcrumb;
          }

          // filter out cast console logs
          if (category === 'console') {
            if (breadcrumb.message?.startsWith('Cast')) return null;
            if (breadcrumb.message?.startsWith('[mux]')) return null;
          }
          if (/fetch|xhr/.test(category ?? '')) {
            // filter out swimlane fetches
            if (/\/content-(layout|search)\//i.test(breadcrumb.data?.url ?? '')) return null;
            // filter out by (part of) host
            if (
              [
                // trackers
                '.doubleclick.net',
                'google-analytics.com',
                'hotjar.com',
                'stm.strim.no',
                'rikstv.psplugin.com', // vergic
                '.clarity.ms/collect',
                'client-analytics.braintreegateway.com',
                'events.launchdarkly.com/events/diagnostic/',
                // video related requests
                '.litix.io', // mux
                'appeartv-cdn.rikstv.no',
                '.telenorcdn.net',
                '.nep.ms/',
                // progress update
                '/progress/',
                // 3rd parties
                'app.launchdarkly.com',
                'tr.snapchat.com',
              ].some(pathSegment => breadcrumb.data?.url?.includes(pathSegment))
            )
              return null;
          }

          return breadcrumb;
        },
      });
      // set referrer as tag, Sentry has a "field" "http.referer" but that is always blank in our case for some reason
      Sentry.setTag('referrer', document.referrer);
    }
  }

  addBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
    Sentry.addBreadcrumb(breadcrumb);
  }

  captureException(_error: Error, sentryContext: Partial<ScopeContext> = {}) {
    const error = _error as EnhancedError;
    sentryContext.extra ??= {};
    sentryContext.tags ??= {};
    sentryContext.extra.errorObject = error;
    if (error.cause) {
      // add cause as own extra object and remove from errorObject
      sentryContext.extra.errorCauseObject = error.cause;
      delete (sentryContext.extra.errorObject as EnhancedError).cause;
    }

    // Add additional properties as tags
    if (error.code != null) {
      sentryContext.tags.errorCode = error.code;
    }
    if (error.drmValidTo != null) {
      sentryContext.tags.drmValidTo = error.drmValidTo;
    }
    // Payment errors, for one, can have RiksTVRequestError as cause
    addTagsFromRiksTVRequestError(error, sentryContext);
    addTagsFromRiksTVRequestError(error.cause, sentryContext);

    if (!this.errorTrackerFeature.thisEnvironmentShouldLogToServer() && !isRunningTests()) {
      log.error(error, sentryContext);
    } else {
      if (isError(error)) {
        Sentry.captureException(error, sentryContext);
      } else {
        // Sentry will log the error object as message if isError is false, which usually
        // ends up as [object Object]. We add a slightly more informative text instead.

        // Check what happens with the new Sentry SDK - maybe this isn't needed anymore
        const message = `Custom error (${typeof error}): ${JSON.stringify(error)}`;
        this.captureMessage(message, sentryContext);
      }
    }
  }

  logMessage(message: string, context?: CaptureContext) {
    log.info(message, context);
    if (!this.errorTrackerFeature.thisEnvironmentShouldLogToServer()) {
      return;
    }
    this.captureMessage(message, context);
  }

  setUserId(userId: number) {
    Sentry.setUser({
      id: userId.toString(),
    });
  }

  capture404NotFound(context?: Omit<Partial<ScopeContext>, 'fingerprint' | 'level'>) {
    // "url" and "referer" passed by default by sentry so no need to add to message
    this.captureMessage('Page Not Found', { level: 'warning', fingerprint: ['404-not-found'], ...context });
  }

  private captureMessage(message: string, context?: CaptureContext) {
    Sentry.captureMessage(message, context);
  }
}

const addTagsFromRiksTVRequestError = (error: unknown, sentryContext: Partial<ScopeContext>) => {
  if (!(error instanceof Error)) {
    return;
  }
  sentryContext.tags ??= {};
  // Add http status code for failed request
  if ('statusCode' in error && typeof error.statusCode === 'number') {
    sentryContext.tags.httpStatus = error.statusCode;
  }
  // Add failed request url
  if ('failedUrl' in error && typeof error.failedUrl === 'string') {
    // Tags have a max length of 200, otherwise discarded by Sentry
    sentryContext.tags.failedUrl = trimTagString(error.failedUrl);
  }
  // Add body of response
  if (error instanceof RiksTVRequestError) {
    if (error.response?.body != null) {
      // Tags have a max length of 200, otherwise discarded by Sentry
      if (typeof error.response.body === 'string') {
        // trim string and replace userId, so that e.g.: "Customer '100303724' is " => "Customer '<userId>' is "
        sentryContext.tags.httpBody = trimTagString(error.response.body.replace(/(\d{8,})/, '<userId>'));
      } else if (error.response.body instanceof Object) {
        try {
          sentryContext.tags.httpBody = trimTagString(JSON.stringify(error.response.body));
        } catch {
          /* */
        }
      }
    }
  }
};
const trimTagString = (str: string) => str.substring(0, 199);
